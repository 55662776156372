import { createFileRoute } from "@tanstack/react-router";
import { api } from "../lib/api";
import Blobs from "../screens/Blobs";

export const Route = createFileRoute("/_layout/blobs")({
  loader: async () => {
    return api.getBlobsMetadata();
  },
  component: BlobsComponent,
});

function BlobsComponent() {
  const data = Route.useLoaderData();

  return <Blobs data={data} />;
}

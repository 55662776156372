import { create } from "zustand";
import { LiveTrackerStat, api } from "./lib/api";
import type { BlockData } from "./components/TrackerTable";
import { BlobData, BlobMetadata } from "./screens/Blobs";

type FilterKeys = {
  da: string[];
  stack: string[];
  parentChain: string[];
};

type State = {
  eventSource: EventSource;
  blockData: BlockData;
  filter: FilterKeys;
  filterOpen: boolean;
  /**
   * Blobs Stuff
   */
  blobsEventSource: EventSource;
  blobData: BlobData;
};

type Action = {
  blockDataSet: (update: LiveTrackerStat) => void;
  filterSet: (update: Partial<FilterKeys>) => void;
  filterOpenSet: (update: boolean) => void;
  blobDataSet: (update: BlobMetadata) => void;
};

const useStore = create<State & Action>((set) => ({
  eventSource: api.getEventSource(),
  blockData: {},
  blockDataSet: (update: LiveTrackerStat) =>
    set((state) => ({
      blockData: {
        ...state.blockData,
        [update.name]: update,
      },
    })),
  filter: {
    da: [],
    stack: [],
    parentChain: [],
  },
  filterSet: (update) =>
    set((state) => ({ filter: { ...state.filter, ...update } })),
  filterOpen: false,
  filterOpenSet: (update) => set({ filterOpen: update }),
  /**
   * Blobs Stuff
   */
  blobsEventSource: api.getBlobEventSource(),
  blobData: {},
  blobDataSet: (update) =>
    set((state) => ({
      blobData: {
        ...state.blobData,
        [update.da]: update,
      },
    })),
}));

export default useStore;

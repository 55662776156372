import { useMemo, useState } from "react";
import BlobCalculator from "../components/BlobCalculator";
import BlobRow from "../components/BlobRow";
import { Column, Sort } from "../components/TrackerTable";
import TrackerTableColumnHeader from "../components/TrackerTableColumnHeader";
import { sortNumberValue, sortStringValue } from "../lib/utils";
import { useShallow } from "zustand/react/shallow";
import useStore from "../store";
import { BlobMetadataResponse } from "../lib/api";

export type TooltipData = {
  value: string;
  formatted: string;
  /**
   * Optional tooltip text
   */
  tooltip?: string;
};

export type BlobMetadata = {
  da: string;
  payment: string;
  blobsize: number;
  price: number;
  latency: number;
  tooltipData: {
    [key: string]: TooltipData;
  };
};

export type BlobData = {
  [key: string]: BlobMetadata;
};

export default function Blobs({ data }: { data: BlobMetadataResponse }) {
  const blobData = useStore(useShallow((state) => state.blobData));

  const [sortColumn, sortColumnSet] = useState<Sort | undefined>({
    accessorKey: "price",
    order: "asc",
  });

  const mergedStaticWithLiveData = useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.values(data).map((networkMetadata) => ({
      ...networkMetadata,
      /**
       * This is live data, merged with static data
       */
      ...blobData[networkMetadata.da],
    }));
  }, [blobData, data]);

  const mergedAndSortedData = useMemo(() => {
    return mergedStaticWithLiveData.sort((a, b) => {
      if (!sortColumn) {
        return 0;
      }

      switch (sortColumn.accessorKey) {
        case "da":
          return sortStringValue(a?.da, b?.da, sortColumn.order);
        case "payment":
          return sortStringValue(a?.payment, b?.payment, sortColumn.order);
        case "blobsize":
          return sortNumberValue(a?.blobsize, b?.blobsize, sortColumn.order);
        case "latency":
          return sortNumberValue(a?.latency, b?.latency, sortColumn.order);
        case "price":
          return sortNumberValue(a?.price, b?.price, sortColumn.order);
        default:
          return 0;
      }
    });
  }, [mergedStaticWithLiveData, sortColumn]);

  const columns: Column[] = [
    {
      header: "DA",
      accessorKey: "da",
    },
    {
      header: "Pay",
      accessorKey: "payment",
    },
    {
      header: "$/kB",
      accessorKey: "price",
    },
    {
      header: "Size",
      accessorKey: "blobsize",
    },
    {
      header: "Δt",
      accessorKey: "latency",
    },
  ];

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="center">
        <div className="tui-progress-bar inline-block valign-middle">
          <span className="tui-indeterminate"></span>
        </div>
      </div>
    );
  }

  return (
    <>
      <BlobCalculator data={mergedStaticWithLiveData} />

      <table className="tui-table hovered-cyan w-full">
        <TrackerTableColumnHeader
          columns={columns}
          sortColumn={sortColumn}
          sortColumnSet={sortColumnSet}
        />

        <tbody>
          {mergedAndSortedData.map((data, idx) => (
            <BlobRow key={`${data.da}-${idx}`} metadata={data} />
          ))}
        </tbody>
      </table>

      <div className="flex gap-4 flex-wrap -mb-3">
        <p className="text-xs">
          <span className="align-super text-xs ml-0.5">{"1"}</span> Free up to
          1.28kbps for 12 months
        </p>
      </div>
    </>
  );
}

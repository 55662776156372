import {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { BlobMetadata } from "../screens/Blobs";
import { formatNumeral, unformatNumeral } from "cleave-zen";
import { formatCurrency } from "../lib/utils";

function calculatePriceFromBytes(bytes: string, pricePerByte: number) {
  if (bytes.trim() === "") {
    return "0";
  }

  const bytesNumber = parseFloat(bytes);

  if (isNaN(bytesNumber)) {
    return "0";
  }

  return (pricePerByte * bytesNumber).toFixed(2);
}

function calculateBytesFromPrice(price: string, pricePerByte: number) {
  if (price.trim() === "") {
    return "0";
  }

  const priceNumber = parseFloat(price);

  if (isNaN(priceNumber)) {
    return "0";
  }

  return (priceNumber / pricePerByte).toFixed(2);
}

export default function BlobCalculator({ data }: { data: BlobMetadata[] }) {
  const sortedDa = useMemo(
    () => data.sort((a, b) => a.da.localeCompare(b.da)),
    [data],
  );

  const [, startTransition] = useTransition();

  const [da, setDa] = useState(
    sortedDa.find((d) => d.da === "Eth Calldata")?.da ?? sortedDa[0].da,
  );

  function handleDaChange(e: ChangeEvent<HTMLSelectElement>) {
    startTransition(() => {
      setDa(e.currentTarget.value);
    });
  }

  const currentDaPrice = useMemo(() => {
    return data.find((d) => d.da === da)?.price ?? 0;
  }, [da, data]);

  const [formattedBytes, formattedBytesSet] = useState<string>("110");
  const [bytes, setBytes] = useState<string>("110");

  const [formattedPrice, formattedPriceSet] = useState<string>("0");
  const [price, setPrice] = useState<string>("0");

  function handlePriceChange(e: ChangeEvent<HTMLInputElement>) {
    startTransition(() => {
      const inputValue = e.currentTarget.value;

      formattedPriceSet(
        formatNumeral(inputValue, {
          prefix: "$",
          numeralPositiveOnly: true,
        }),
      );

      setPrice(unformatNumeral(inputValue));

      setBytes(calculateBytesFromPrice(inputValue, currentDaPrice));
    });
  }

  function handleBytesChange(e: ChangeEvent<HTMLInputElement>) {
    startTransition(() => {
      const inputValue = e.currentTarget.value;

      formattedBytesSet(
        formatNumeral(inputValue, {
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
        }),
      );

      setBytes(unformatNumeral(inputValue));

      setPrice(calculatePriceFromBytes(inputValue, currentDaPrice));
    });
  }

  const [activeInput, setActiveInput] = useState<"bytes" | "price">("bytes");

  useEffect(() => {
    if (activeInput === "bytes") {
      setPrice(calculatePriceFromBytes(bytes, currentDaPrice));

      formattedPriceSet(
        formatNumeral(calculatePriceFromBytes(bytes, currentDaPrice), {
          prefix: "$",
          numeralPositiveOnly: true,
        }),
      );
    } else {
      setBytes(calculateBytesFromPrice(price, currentDaPrice));

      formattedBytesSet(
        formatNumeral(calculateBytesFromPrice(price, currentDaPrice), {
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
        }),
      );
    }
  }, [activeInput, bytes, currentDaPrice, price]);

  return (
    <div className="tui-window tui-no-shadow w-full">
      <fieldset className="tui-fieldset pt-[6px]">
        <legend className="center whitespace-nowrap">Blob Calculator</legend>

        <div className="flex flex-col md:flex-row items-stretch md:items-start gap-4">
          <div className="flex flex-col flex-1 items-center gap-2">
            <label htmlFor="bytes">
              Size {activeInput === "bytes" && `[M]`}
            </label>

            <input
              id="bytes"
              name="bytes"
              className="tui-input text-center w-full max-w-xs"
              value={formattedBytes}
              onChange={handleBytesChange}
              onFocus={() => setActiveInput("bytes")}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              aria-describedby="bytes-label"
            />

            <span id="bytes-label">KiloBytes</span>
          </div>

          <div className="flex flex-col flex-1 items-center gap-2">
            <label htmlFor="da">DA $/kB</label>

            <div className="relative w-full max-w-xs">
              <select
                id="da"
                name="da"
                className="tui-input w-full peer"
                value={da}
                onChange={handleDaChange}
                aria-describedby="da-label"
              >
                {sortedDa.map((d) => (
                  <option key={d.da} value={d.da}>
                    {d.da}
                  </option>
                ))}
              </select>

              <svg
                className="inline size-4 absolute peer-focus:text-black right-1 top-1 z-10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7 8H5v2h2v2h2v2h2v2h2v-2h2v-2h2v-2h2V8h-2v2h-2v2h-2v2h-2v-2H9v-2H7V8z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <span id="da-label">
              {currentDaPrice !== undefined
                ? `${formatCurrency(currentDaPrice, 5, 5)}`
                : ""}
            </span>
          </div>

          <div className="flex flex-col flex-1 items-center gap-2">
            <label htmlFor="price">
              Cost {activeInput === "price" && `[M]`}
            </label>

            <input
              id="price"
              name="price"
              className="tui-input text-center w-full max-w-xs"
              value={formattedPrice}
              onChange={handlePriceChange}
              onFocus={() => setActiveInput("price")}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              aria-describedby="price-label"
            />

            <label id="price-label">USD</label>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

import type { BlobMetadata } from "../screens/Blobs";

export type TrackerStat = {
  blockNumber: number;
  dataCount: number;
  gasCount: number;
  timestamp: number;
  txCount: number;
  networkId: string;
  label: string;
};

export type LiveTrackerStat = {
  blockNumber: number;
  dataCount: number;
  gasCount: number;
  timestamp: number;
  txCount: number;
  tps: string;
  gps: string;
  dps: string;
} & { name: string };

export type NetworkMetadata = {
  name: string;
  ws: string;
  label: string;
  website: string;
  explorer: string;
  parentChain: string;
  da: string;
  stack: string;
  provider: string;
  superchain?: undefined;
};

export type TrackerStatsResponse = {
  [key: string]: TrackerStat;
};

export type NetworkMetadataResponse = {
  [key: string]: NetworkMetadata;
};

export type BlobMetadataResponse = {
  [key: string]: BlobMetadata;
};

class TrackerAPI {
  protected baseUrl: string;

  constructor() {
    // @ts-expect-error - this is a hack to get the env variable
    this.baseUrl = import.meta.env.VITE_PUBLIC_API_URL as string;
  }

  public async getStats(): Promise<TrackerStatsResponse> {
    try {
      const r = await fetch(`${this.baseUrl}/stats`);

      if (r.ok) {
        return r.json();
      }

      throw new Error("cannot fetch stats");
    } catch (error) {
      throw new Error("cannot fetch stats");
    }
  }

  public async getNetworkMetadata(): Promise<NetworkMetadataResponse> {
    try {
      const r = await fetch(new URL("/networkMetadata", this.baseUrl));

      if (r.ok) {
        return r.json();
      }

      throw new Error("cannot fetch metadata");
    } catch (err) {
      throw new Error("cannot fetch metadata");
    }
  }

  public getEventSource() {
    return new EventSource(new URL("/sse", this.baseUrl));
  }

  public async getBlobsMetadata(): Promise<BlobMetadataResponse> {
    try {
      const r = await fetch(new URL("/blobs/metadata", this.baseUrl));

      if (r.ok) {
        return r.json();
      }

      throw new Error("cannot fetch blobs metadata");
    } catch (err) {
      throw new Error("cannot fetch blobs metadata");
    }
  }

  public getBlobEventSource() {
    return new EventSource(new URL("/blobs/sse", this.baseUrl));
  }
}

export const api = new TrackerAPI();

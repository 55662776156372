import { useState, useEffect } from "react";

const asciiChars = ["|", "/", "-", "\\"];

const TextSpinner = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % asciiChars.length);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span>{asciiChars[index]}</span>;
};

export default TextSpinner;

import { createRootRoute, Outlet } from "@tanstack/react-router";
import { lazy, Suspense } from "react";

const TanStackRouterDevtools =
  // @ts-expect-error - This is a hack to get the Devtools to work in production
  "production" === "production"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

export const Route = createRootRoute({
  component: () => (
    <>
      <Outlet />

      <div className="dos bottom-4 left-4 right-4 mb-2 mt-3 flex w-full max-w-3xl items-end justify-between xl:fixed xl:mt-0 xl:max-w-[unset] xl:w-[unset]">
        <div>
          Copyright © 2024
          <br />
          ConduitXYZ Inc.
        </div>

        <div>Rollup.wtf</div>
      </div>

      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
});

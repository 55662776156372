import * as Popover from "@radix-ui/react-popover";
import type { NetworkMetadata } from "../lib/api";
import InfoToolTip from "./InfoToolTip";

export default function TrackerRowHeader({
  metadata: nm,
}: {
  metadata: NetworkMetadata;
}) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className="flex flex-row items-center justify-between tracking-tight hover:underline hover:cursor-pointer">
          {nm.label}
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="tui-window w-[22rem]"
          side="bottom"
          sideOffset={16}
        >
          <fieldset className="min-w-0 tui-fieldset">
            <legend className="center">{nm.label}</legend>

            <Popover.Close className="tui-fieldset-button">
              <span className="green-255-text">■</span>
            </Popover.Close>

            <dl>
              {nm.website && (
                <div className="flex justify-between gap-4">
                  <dt className="text-sm text-gray-200">Website</dt>

                  <dd className="truncate">
                    <a
                      className="text-sm hover:underline"
                      target="_blank"
                      rel="noopener nofollow"
                      href={nm.website}
                    >
                      {nm.website.replace(/^https?:\/\//, "")}
                    </a>
                  </dd>
                </div>
              )}

              {nm.explorer && (
                <div className="flex items-center justify-between gap-4">
                  <dt className="text-sm text-gray-200">Explorer</dt>

                  <dd className="truncate">
                    <a
                      className="text-sm hover:underline"
                      target="_blank"
                      rel="noopener nofollow"
                      href={nm.explorer}
                    >
                      {nm.explorer.replace(/^https?:\/\//, "")}
                    </a>
                  </dd>
                </div>
              )}

              <div className="flex items-center justify-between gap-4">
                <InfoToolTip
                  showInfoIcon={true}
                  toolTipText="The tech stack used by the network."
                >
                  <dt className="text-sm text-gray-200">Stack</dt>
                </InfoToolTip>

                <dd className="text-sm capitalize truncate">
                  {nm.stack ?? "N/A"}
                </dd>
              </div>

              <div className="flex items-center justify-between gap-4">
                <InfoToolTip
                  showInfoIcon={true}
                  toolTipText="Where Data Availability is located. Important for the network security."
                >
                  <dt className="text-sm text-gray-200">DA</dt>
                </InfoToolTip>

                <dd className="text-sm capitalize truncate">
                  {nm.da ?? "N/A"}
                </dd>
              </div>

              <div className="flex items-center justify-between gap-4">
                <dt className="text-sm text-gray-200">Provider</dt>

                <dd className="text-sm capitalize truncate">
                  {nm.provider ?? "N/A"}
                </dd>
              </div>

              {nm.superchain && (
                <div className="flex items-center justify-between gap-4">
                  <dt className="text-sm text-gray-200">Superchain</dt>

                  <InfoToolTip toolTipText="The Superchain is a collective of rollups built on the OP Stack">
                    <dd className="text-sm capitalize truncate hover:underline-offset-1">
                      {nm.superchain ? "Yes" : "No"}
                    </dd>
                  </InfoToolTip>
                </div>
              )}
            </dl>
          </fieldset>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

import { Outlet, Link, createFileRoute } from "@tanstack/react-router";

import {
  ADD_DA_URL,
  ADD_ROLLUP_URL,
  CONDUIT_URL,
  SEND_FEEDBACK_URL,
} from "../constants/urls";

export const Route = createFileRoute("/_layout")({
  component: WindowLayout,
});

function WindowLayout() {
  return (
    <>
      <main className="tui-window max-w-4xl w-full">
        <fieldset className="tui-fieldset">
          <legend className="center">
            Rollup.wtf || Built by{" "}
            <a href={CONDUIT_URL} className="underline">
              Conduit.xyz
            </a>
          </legend>

          <div className="tui-window full-width tui-no-shadow left-align">
            <fieldset className="tui-fieldset no-legend">
              <ul className="grid grid-cols-2 gap-5 place-items-center">
                <li>
                  <Link
                    to="/"
                    className="cursor-pointer white-255-hover black-text-hover [&.active]:bg-white [&.active]:text-black"
                  >
                    Rollups
                  </Link>
                </li>

                <li>
                  <Link
                    to="/blobs"
                    className="cursor-pointer white-255-hover black-text-hover [&.active]:bg-white [&.active]:text-black"
                  >
                    Blobs
                  </Link>
                </li>
              </ul>
            </fieldset>
          </div>

          <Outlet />
        </fieldset>
      </main>

      <footer className="mt-4 flex items-center justify-center gap-4">
        <div className="info-text">
          <a
            className="hover:underline"
            href={SEND_FEEDBACK_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Feedback.exe
          </a>
        </div>

        <div className="info-text">
          <a
            className="hover:underline"
            href={ADD_ROLLUP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            AddRollup.exe
          </a>
        </div>

        <div className="info-text">
          <a
            className="hover:underline"
            href={ADD_DA_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            AddDA.exe
          </a>
        </div>
      </footer>
    </>
  );
}

export function cn(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function sortNumberValue(
  a: string | number,
  b: string | number,
  order: "asc" | "desc",
) {
  const aValue = typeof a === "number" ? a : parseFloat(a);
  const bValue = typeof b === "number" ? b : parseFloat(b);
  if (isNaN(aValue)) return 1;
  if (isNaN(bValue)) return -1;
  return order === "asc" ? aValue - bValue : bValue - aValue;
}

export function sortStringValue(a: string, b: string, order: "asc" | "desc") {
  return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
}

export function formatCurrency(
  value: number,
  maxDecimals = 2,
  minDecimals?: number,
) {
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
    currencyDisplay: "symbol",
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: minDecimals,
  }).format(value);
}

import useSWRSubscription from "swr/subscription";
import { useShallow } from "zustand/react/shallow";
import { type LiveTrackerStat, type NetworkMetadata } from "../lib/api";
import useStore from "../store";
import Spinner from "./TextSpinner";
import TrackerRowHeader from "./TrackerRowHeader";
import { chainIdToName } from "../constants/chains";

export default function TrackerRow({
  metadata,
}: {
  metadata: NetworkMetadata;
}) {
  const eventSource = useStore(useShallow((state) => state.eventSource));

  const blockDataMutate = useStore(useShallow((state) => state.blockDataSet));

  const { data, error } = useSWRSubscription<
    LiveTrackerStat,
    Error,
    ["getEventSource", string]
  >(
    ["getEventSource", metadata.name],
    ([, eventName], { next }) => {
      function listener(event: MessageEvent) {
        try {
          const tmp = JSON.parse(event.data) as LiveTrackerStat;

          blockDataMutate({ ...tmp, name: metadata.name });

          next(null, tmp);
        } catch (err) {
          console.error(err);

          next(err as Error);
        }
      }

      eventSource.addEventListener(eventName, listener);

      return () => {
        eventSource.removeEventListener(eventName, listener);
      };
    },
    { shouldRetryOnError: false },
  );

  if (error) {
    return null;
  }

  return (
    <tr
      className={
        metadata?.label === "Ethereum" ? "bg-[#00A800] black-text" : ""
      }
    >
      <td className="leading-normal">
        <TrackerRowHeader metadata={metadata} />
      </td>

      <td className="leading-normal text-right">
        {data?.blockNumber ? (
          metadata.explorer ? (
            <a
              className="hover:underline"
              href={metadata.explorer}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.blockNumber}
            </a>
          ) : (
            data.blockNumber
          )
        ) : (
          <Spinner />
        )}
      </td>

      <td className="leading-normal text-right">{data?.tps ?? <Spinner />}</td>

      <td className="leading-normal text-right">{data?.gps ?? <Spinner />}</td>

      <td className="leading-normal text-right">{data?.dps ?? <Spinner />}</td>

      <td className="leading-normal text-right hidden lg:table-cell ">
        <span className="pl-2 truncate">{metadata?.stack ?? <Spinner />}</span>
      </td>

      <td className="leading-normal text-right hidden lg:table-cell ">
        <span className="pl-2 truncate">{metadata?.da ?? <Spinner />}</span>
      </td>

      <td className="leading-normal text-right hidden lg:table-cell ">
        <span className="pl-2 truncate">
          {metadata?.parentChain ? (
            chainIdToName[
              metadata.parentChain as unknown as keyof typeof chainIdToName
            ]
          ) : (
            <Spinner />
          )}
        </span>
      </td>
    </tr>
  );
}

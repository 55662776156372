import { createFileRoute } from "@tanstack/react-router";
import { api } from "../lib/api";
import TrackerTable from "../components/TrackerTable";

export const Route = createFileRoute("/_layout/")({
  loader: async () => {
    return api.getNetworkMetadata();
  },
  component: Index,
});

function Index() {
  const data = Route.useLoaderData();

  return <TrackerTable data={data} />;
}

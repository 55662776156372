import * as Tooltip from "@radix-ui/react-tooltip";

const InfoIcon = () => (
  <svg
    className="inline size-4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M3 3h2v18H3V3zm16 0H5v2h14v14H5v2h16V3h-2zm-8 6h2V7h-2v2zm2 8h-2v-6h2v6z"
      fill="currentColor"
    />
  </svg>
);

export default function InfoTooltip(props: {
  toolTipText: string;
  showInfoIcon?: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="cursor-help flex gap-1 justify-center">
            {props.children}

            {props.showInfoIcon && <InfoIcon />}
          </div>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content
            className="tui-panel green-168 black-255-text max-w-xs text-sm px-3 pt-2.5 pb-2 z-50"
            sideOffset={5}
          >
            {props.toolTipText}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

import { useShallow } from "zustand/react/shallow";
import TextSpinner from "./TextSpinner";
import useStore from "../store";
import useSWRSubscription from "swr/subscription";
import BlobRowHeader from "./BlobRowHeader";
import { formatCurrency } from "../lib/utils";
import { BlobMetadata } from "../screens/Blobs";

export default function BlobRow({ metadata }: { metadata: BlobMetadata }) {
  const eventSource = useStore(useShallow((state) => state.blobsEventSource));

  const blobDataMutate = useStore(useShallow((state) => state.blobDataSet));

  const { data, error } = useSWRSubscription<
    BlobMetadata,
    Error,
    ["getBlobEventSource", string]
  >(
    ["getBlobEventSource", metadata.da],
    ([, eventName], { next }) => {
      function listener(event: MessageEvent) {
        try {
          const tmp = JSON.parse(event.data) as BlobMetadata;

          blobDataMutate({ ...tmp, da: metadata.da });

          next(null, tmp);
        } catch (err) {
          console.error(err);

          next(err as Error);
        }
      }

      eventSource.addEventListener(eventName, listener);

      return () => {
        eventSource.removeEventListener(eventName, listener);
      };
    },
    { shouldRetryOnError: false },
  );

  if (error) {
    return null;
  }

  return (
    <tr>
      <td className="leading-normal">
        <BlobRowHeader metadata={metadata} />
      </td>

      <td className="leading-normal text-right">{metadata.payment}</td>

      <td className="leading-normal text-right">
        {data?.price !== undefined ? (
          formatCurrency(data.price, 5, 5)
        ) : (
          <TextSpinner />
        )}
      </td>

      <td className="leading-normal text-right">
        {`${metadata.blobsize.toLocaleString()} kB`}
      </td>

      <td className="leading-normal text-right">{`${metadata.latency}s`}</td>
    </tr>
  );
}

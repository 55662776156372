import type { Column, Sort } from "./TrackerTable";

const SortableIcon = () => (
  <svg
    className="inline size-4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M11 4h2v2h-2V4zM9 8V6h2v2H9zm0 0v2H7V8h2zm6 0h-2V6h2v2zm0 0h2v2h-2V8zm-6 8H7v-2h2v2zm2 2H9v-2h2v2zm2 0v2h-2v-2h2zm2-2h-2v2h2v-2zm0 0v-2h2v2h-2z"
      fill="currentColor"
    />
  </svg>
);

const SortAscIcon = () => (
  <svg
    className="inline size-4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M7 8H5v2h2v2h2v2h2v2h2v-2h2v-2h2v-2h2V8h-2v2h-2v2h-2v2h-2v-2H9v-2H7V8z"
      fill="currentColor"
    />
  </svg>
);

const SortDescIcon = () => (
  <svg
    className="inline size-4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M7 16H5v-2h2v-2h2v-2h2V8h2v2h2v2h2v2h2v2h-2v-2h-2v-2h-2v-2h-2v2H9v2H7v2z"
      fill="currentColor"
    />
  </svg>
);

export default function TrackerTableColumnHeader({
  columns,
  sortColumn,
  sortColumnSet,
}: {
  columns: Column[];
  sortColumn?: Sort;
  sortColumnSet: React.Dispatch<React.SetStateAction<Sort | undefined>>;
}) {
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.accessorKey} className={column.className}>
            <div
              className="inline-flex items-center gap-1 cursor-pointer select-none"
              onClick={() => {
                if (
                  sortColumn &&
                  sortColumn.accessorKey === column.accessorKey &&
                  sortColumn.order === "desc"
                ) {
                  return sortColumnSet(undefined);
                }

                sortColumnSet({
                  accessorKey: column.accessorKey,
                  order:
                    sortColumn && sortColumn.accessorKey === column.accessorKey
                      ? sortColumn.order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc",
                  live: column.live,
                });
              }}
            >
              <SortableIcon />

              {column.header}

              {sortColumn &&
                sortColumn.accessorKey === column.accessorKey &&
                (sortColumn.order === "asc" ? (
                  <SortAscIcon />
                ) : (
                  <SortDescIcon />
                ))}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}

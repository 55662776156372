import { RouterProvider, createRouter } from "@tanstack/react-router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";

import "tuicss";
import "./index.css";

import { routeTree } from "./routeTree.gen";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Banner, cookieConsentGiven } from "./components/Banner";

const router = createRouter({
  routeTree,
  defaultErrorComponent: ({ error }) => <ErrorBoundary error={error} />,
  defaultPreload: "intent",
});

posthog.init("phc_ma4zt4L422jxE5mvhlRoDcmeOC2x3uUMJKyAEEqCVUb", {
  api_host: "https://rollup.wtf/ingest",
  ui_host: "https://us.posthog.com",
  person_profiles: "identified_only",
  persistence:
    cookieConsentGiven() === "yes" ? "localStorage+cookie" : "memory",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <RouterProvider router={router} />

      <Banner />
    </PostHogProvider>
  </React.StrictMode>,
);

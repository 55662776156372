export function ErrorBoundary({ error }: { error: Error }) {
  return (
    <main className="tui-window">
      <fieldset className="tui-fieldset">
        <legend className="center">Error.exe</legend>

        <pre className="info-text mb-4">
          {(error as Error)?.message ?? "Something went wrong"}
        </pre>

        <button className="tui-button" onClick={() => window.location.reload()}>
          Retry
        </button>
      </fieldset>
    </main>
  );
}
